@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.error-page {
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.error-screen {
  &__wrapper {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 300px;
  }

  &__header, &__text {
    color: $red;
  }

  &__header {
    i {
      margin-right: 4px;
    }
  }
}
